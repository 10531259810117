export default {
    methods: {
        getInitialRange() {
            return {
                startDate: this.$dayjs()
                    .subtract(1, 'month')
                    .startOf('day')
                    .toDate(),
                endDate: this.$dayjs()
                    .endOf('day')
                    .toDate(),
            };
        },
        getFilters() {
            const form_data = { ...this.formData };
            form_data['created_at[from]'] = this.$dayjs(form_data.range.startDate).toISOString();
            form_data['created_at[to]'] = this.$dayjs(form_data.range.endDate).toISOString();

            delete form_data.range;

            return form_data;
        },
    }
}