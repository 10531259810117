<template>
    <ui-modal
        ref="modal"
        class="download-report-modal"
        title="Download report"
        size="md"
        centered
        @hidden="clearModal"
    >
        <p v-if="reportData?.type !== 'transactionsDocument'" class="download-report-modal__form-description">
            Select the filters with which you want to download the report
        </p>
        <component v-if="currentFilters" ref="reportfilters" :is="currentFilters" />
        <template #footer>
            <ui-button variant="light" @click="hide">
                Close
            </ui-button>
            <ui-button
                type="button"
                variant="primary"
                @click="startDownload"
            >
                Download
            </ui-button>
        </template>
    </ui-modal>
</template>

<script>
import UiButton from '@/components/ui/buttons/Button';
import UiLinearProgress from '@/components/ui/progress/LinearProgress';
import UiModal from '@/components/ui/Modal';

// Available filters
import TransactionsFilters from '@/components/reports/filters/Transactions';
import WithdrawalsFilters from '@/components/reports/filters/Withdrawals';
import TransactionByDocument from '@/components/reports/filters/TransactionByDocument';
import AboutYourSelf from '@/components/reports/filters/AboutYourSelf.vue';
import ActivityFilters from '@/components/reports/filters/Activity.vue';
import ChargebacksFilters from '@/components/reports/filters/Chargebacks.vue';
import RefundsFilters from '@/components/reports/filters/Refunds.vue';

export default {
    name: 'DownloadReportModal',
    components: {
        UiButton,
        UiLinearProgress,
        UiModal,
        TransactionsFilters,
        WithdrawalsFilters,
        TransactionByDocument,
        AboutYourSelf,
        ActivityFilters,
        ChargebacksFilters,
        RefundsFilters
    },
    data() {
        return {
            currentFilters: null,
            reportData: null,
        };
    },
    mounted() {
        this.modal = this.$refs.modal;
    },
    methods: {
        show(report_data) {
            const available_filters = {
                transactions: 'TransactionsFilters',
                withdrawals: 'WithdrawalsFilters',
                transactionsDocument: 'TransactionByDocument',
                aboutYourSelf: 'AboutYourSelf',
                activity: 'ActivityFilters',
                chargebacks: 'ChargebacksFilters',
                refunds: 'RefundsFilters',
            };

            if (report_data?.type) {
                this.currentFilters = available_filters[report_data.type] || null;
            }

            this.reportData = { ...report_data };
            this.modal.show();
        },
        hide() {
            this.modal.hide();
        },
        clearModal() {
            this.reportData = null;
            this.currentFilters = null;
        },
        async startDownload() {
            const search_params = new URLSearchParams();
            let filters = { ...this.$refs.reportfilters.getFilters() };
            
            if (this.reportData.type === 'transactionsDocument') {
                const formData = new FormData();
                formData.append('file', filters.file);
                const { data } = await this.$axios.post(`/_/files/tmp`, formData, { 'Content-Type': 'multipart/form-data' });
                filters = {file_id: data.id}
            }
            
            for (const key in filters) {
                if (filters[key]) {
                    search_params.append(key, filters[key]);
                }
            }

           this.reportData.url = `${this.reportData.url}?${search_params.toString()}`;
           this.$emit('download', { ...this.reportData });
           this.hide();
        },
    },
};
</script>
