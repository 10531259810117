<template>
    <div class="reports">
        <div class="row">
            <div class="col-sm-6 col-md-4 col-xl-3">
                <ui-card title="Withdrawals report">
                    <img
                        alt="Withdrawals"
                        class="reports__icon"
                        src="@/assets/img/reports/withdrawals.svg"
                    />
                    <ui-button
                        icon="fas fa-download"
                        variant="primary"
                        @click="showDownloadModal({
                            type: 'withdrawals',
                            name: 'Withdrawals',
                            url: '/_/reports/withdrawals',
                        })"
                    >
                        Download
                    </ui-button>
                </ui-card>
            </div>
            <div class="col-sm-6 col-md-4 col-xl-3">
                <ui-card title="Transactions report">
                    <img
                        alt="Transactions"
                        class="reports__icon"
                        src="@/assets/img/reports/transactions.svg"
                    />
                    <ui-button
                        icon="fas fa-download"
                        variant="primary"
                        @click="showDownloadModal({
                            type: 'transactions',
                            name: 'Transactions',
                            url: '/_/reports/transactions',
                        })"
                    >
                        Download
                    </ui-button>
                </ui-card>
            </div>
            <div class="col-sm-6 col-md-4 col-xl-3">

                <ui-card title="Verify Transactions">
                    <img
                        alt="Transactions"
                        class="reports__icon"
                        src="@/assets/img/reports/verify.svg"
                    />
                    <ui-button
                        icon="fas fa-download"
                        variant="primary"
                        @click="showDownloadModal({
                            type: 'transactionsDocument',
                            name: 'TransactionsDocument',
                            url: '/_/reports/transactionsByDocument',
                        })"
                    >
                        Download
                    </ui-button>
                </ui-card>
            </div>
            <div class="col-sm-6 col-md-4 col-xl-3">
                <ui-card title="About yourself">
                    <img
                        alt="abourt yourself"
                        class="reports__icon"
                        src="@/assets/img/reports/about-yourself.svg"
                    />
                    <ui-button
                        icon="fas fa-download"
                        variant="primary"
                        @click="showDownloadModal({
                            type: 'aboutYourSelf',
                            name: 'aboutYourSelf',
                            url: '/_/reports/aboutYourSelf',
                        })"
                    >
                        Download
                    </ui-button>
                </ui-card>
            </div>
            <div class="col-sm-6 col-md-4 col-xl-3">
                <ui-card title="Activity report">
                    <img
                        alt="activity report"
                        class="reports__icon"
                        src="@/assets/img/reports/activity.svg"
                    />
                    <ui-button
                        icon="fas fa-download"
                        variant="primary"
                        @click="showDownloadModal({
                            type: 'activity',
                            name: 'Activity',
                            url: '/_/reports/activity',
                        })"
                    >
                        Download
                    </ui-button>
                </ui-card>
            </div>
            <div class="col-sm-6 col-md-4 col-xl-3">
                <ui-card title="Chargebacks report">
                    <img
                        alt="chargebacks report"
                        class="reports__icon"
                        src="@/assets/img/reports/chargebacks.svg"
                    />
                    <ui-button
                        icon="fas fa-download"
                        variant="primary"
                        @click="showDownloadModal({
                            type: 'chargebacks',
                            name: 'Chargebacks',
                            url: '/_/reports/chargebacks',
                        })"
                    >
                        Download
                    </ui-button>
                </ui-card>
            </div>
            <div class="col-sm-6 col-md-4 col-xl-3">
                <ui-card title="Refunds report">
                    <img
                        alt="refunds report"
                        class="reports__icon"
                        src="@/assets/img/reports/refunds.svg"
                    />
                    <ui-button
                        icon="fas fa-download"
                        variant="primary"
                        @click="showDownloadModal({
                            type: 'refunds',
                            name: 'Refunds',
                            url: '/_/reports/refunds',
                        })"
                    >
                        Download
                    </ui-button>
                </ui-card>
            </div>
        </div>
        <download-report-modal ref="downloadReportModal" @download="downloadReport" />
        <download-report-snackbar ref="downloadReportSnackbar" />
    </div>
</template>
<script>
import DownloadReportModal from '@/components/reports/DownloadReportModal.vue';
import DownloadReportSnackbar from '@/components/reports/DownloadReportSnackbar.vue';
import UiButton from '@/components/ui/buttons/Button';
import UiCard from '@/components/ui/Card.vue';

export default {
    name: 'Reports',
    components: {
        DownloadReportModal,
        DownloadReportSnackbar,
        UiButton,
        UiCard,
    },
    methods: {
        showDownloadModal(report_data) {
            this.$refs.downloadReportModal.show(report_data);
        },
        downloadReport(report) {
            this.$refs.downloadReportSnackbar.download(report);
            this.downloadingReport = true;
        },
    },
};
</script>

<style lang="scss">
.reports {
    &__icon {
        height: 100%;
        margin: 0 auto;
        max-height: 100px;
        width: auto;
    }
}
</style>
