<template>
    <label for="reason" class="form-label">
            Select the file to upload:
        </label>
        <input
            id="file"
            ref="inputFile"
            name="file"
            type="file"
            class="form-control custom-textarea"
            placeholder="Select your file"
            accept=".xlsx"
            :class="[{ 'is-invalid': v$.file.$dirty && v$.file.$error, 'is-valid':  v$.file.$dirty && !v$.file.$error }]"
            @change="change"
        />
        <div v-if="v$.file.$error" class="invalid-feedback">
            {{v$.file.$errors[0].$message}}
        </div>
        <small class="d-block mt-2">You must select a file with the .xlsx" extension.</small>
        <small class="d-block mt-2">
            <a href="https://ecartpay.s3.amazonaws.com/templates/template_transactions.xlsx" download="Transaction_template">
                Download the template here.
            </a>
        </small>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { requiredIf } from '@vuelidate/validators'

import UiButton from '@/components/ui/buttons/Button.vue';
import UiModal from '@/components/ui/Modal.vue';

export default {
    name: 'TransactionByDocument',
    components: { UiButton, UiModal },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            loading: false,
            file: [],
        };
    },
    validations () {
        return {
            file: {
                required: requiredIf(function () {
                    return this.file.length == 0;
                }),
                $autoDirty: true,
            },
        };
    },
    methods: {
        change(event) {
            this.file = event.target.files[0]
        },
        getFilters() {
            const form_data = {
                file: this.file,
            };
            return form_data;
        },
    },
}
</script>