<template>
    <div class="ui-date-range-picker">
        <date-range-picker
            ref="picker"
            :id="id"
            v-model="range"
            opens="right"
            time-picker
            time-picker-24-hours
            show-dropdowns
            control-container-class="ui-date-range-picker__container"
            :linked-calendars="false"
            @update="onUpdate"
        >
            <template v-if="value" #input>
                <em class="fa fa-fw fa-calendar ml-n1"></em>
                {{ $dayjs(range.startDate).format('D MMMM YYYY') }} -
                {{ $dayjs(range.endDate).format('D MMMM YYYY') }}
                <strong class="caret"></strong>
            </template>
        </date-range-picker>
        <div v-if="field && field.$error" class="ui-input__error">
            {{ field.$errors[0].$message }}
        </div>
    </div>
</template>

<script>
import DateRangePicker from 'daterange-picker-vue3';
import 'daterange-picker-vue3/dist/daterange-picker-vue3.css';

export default {
    name: 'PhoneInput',
    components: { DateRangePicker },
    props: {
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        field: {
            type: Object,
            default: null,
            required: false,
        },
        id: {
            type: String,
            required: true,
        },
        value: {
            type: Object,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            range: {
                startDate: null,
                endDate: null
            }
        };
    },
    watch: {
        value(val) {
            this.range = val;
        },
    },
    created() {
        if (this.value) {
            this.range = this.value;
        }
    },
    methods: {
        onUpdate(value) {
            this.$emit('update:value', value);
        },
    },
};
</script>

<style lang="scss">
.ui-date-range-picker {
    &__container {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        height: 40px;
        padding: 4.5px 8px;
        width: 100%;

        .caret {
            margin-left: auto;

            &::before {
                color: $ecart-accent;
                content: '\f078';
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .vue-daterange-picker {
        width: 100%;
    }
}
</style>
