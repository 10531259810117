<template>
    <div class="withdrawals-filters row">
        <div class="col-md-12 mb-3">
            <ui-date-range-picker id="range" v-model:value="formData.range"/>
        </div>
        <div class="col-md-6 mb-3">
            <ui-select-multiple
                id="status"
                name="status"
                label="Status"
                class="w-100"
                option-label="label"
                option-value="value"
                v-model:value="formData.status"
                :options="availableFilters.status"
            />
        </div>
        <div class="col-md-6 mb-3">
            <ui-select-multiple
                id="gateway"
                name="gateway"
                label="Gateway"
                class="w-100"
                option-label="label"
                option-value="value"
                v-model:value="formData.gateway"
                :options="availableFilters.gateway"
            />
        </div>
        <div class="col-md-6 mb-3 md-mb-0">
            <ui-select
                id="in_review"
                class="w-100"
                name="in_review"
                label="In review"
                option-label="label"
                option-value="value"
                chose-option-text="All"
                v-model:value="formData.in_review"
                :options="[{ label: 'Yes', value: 'true' }, { label: 'No', value: 'false' }]"
            />
        </div>
        <div class="col-md-6">
            <ui-select-multiple
                id="currency"
                name="currency"
                label="currency"
                class="w-100"
                option-label="label"
                option-value="value"
                v-model:value="formData.currency"
                :options="availableFilters.currency"
            />
        </div>
    </div>
</template>

<script>
import UiDateRangePicker from '@/components/ui/inputs/DateRangePicker';
import UiSelect from '@/components/ui/Select';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple';

import { filters } from '@/data';
import reports from '@/mixins/reports.mixin';

export default {
    name: 'WithdrawalsFilters',
    components: {
        UiDateRangePicker,
        UiSelect,
        UiSelectMultiple,
    },
    mixins: [reports],
    data() {
        const range = { ...this.getInitialRange() };

        return {
            formData: {
                range: { ...range },
                status: null,
                gateway: null,
                in_review: null,
                currency: null,
            },        
        };
    },
    computed: {
        availableFilters() {
            return filters.withdrawals;
        },
    },
};
</script>
