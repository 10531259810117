<template>
    <div class="ui-linear-progress">
        <legend v-if="!hideLabel" class="ui-linear-progress__legend">
            Completed {{ percentage }}%
        </legend>
        <div class="ui-linear-progress__bar">
            <div class="ui-linear-progress__progress" :style="{ width: `${percentage}%` }"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UiLinearProgress',
    props: {
        hideLabel: {
            type: Boolean,
            default: false,
            required: false,
        },
        max: {
            type: Number,
            default: 100,
            required: false,
        },
        value: {
            type: Number,
            default: 0,
            required: false,
        },
    },
    computed: {
        percentage() {
            return (100 / this.max) * this.value;
        },
    },
};
</script>

<style lang="scss">
.ui-linear-progress {
    &__legend {
        color: $general-black;
        font-size: 0.9rem;
        font-weight: 400;
    }

    &__bar {
        background-color: $ecart-secondary-200;
        border-radius: 2px;
        height: 8px;
        overflow: hidden;
        width: 100%;
    }

    &__progress {
        background-color: $general-success;
        display: flex;
        height: 8px;
        max-width: 100%;
        overflow: hidden;
        transition: width 300ms ease;
    }
}
</style>
