<template>
    <div class="activity-filters row">
        <div class="col-md-12 mb-3">
            <ui-date-range-picker id="range" v-model:value="formData.range"/>
        </div>
    </div>
</template>

<script>
import UiDateRangePicker from '@/components/ui/inputs/DateRangePicker';
import reports from '@/mixins/reports.mixin';

export default {
    name: 'ActivityFilters',
    components: { 
        UiDateRangePicker,
    },
    mixins: [reports],
    data() {
        const range = { ...this.getInitialRange() };

        return {
            formData: {
                range: { ...range },
            },
        };
    },
};
</script>
