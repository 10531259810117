<template>
    <div class="refunds-filters row">
        <div class="col-md-12 mb-3">
            <ui-date-range-picker id="range" v-model:value="formData.range"/>
        </div>
        <div class="col-md-6 mb-3">
            <ui-select-multiple
                id="status"
                name="status"
                label="Status"
                class="w-100"
                option-label="label"
                option-value="value"
                v-model:value="formData.status"
                :options="availableFilters.status"
            />
        </div>
        <div class="col-md-6 mb-3">
            <ui-select-multiple
                id="gateway"
                name="gateway"
                label="Gateway"
                class="w-100"
                option-label="label"
                option-value="value"
                v-model:value="formData.destination"
                :options="availableFilters.gateway"
            />
        </div>
    </div>
</template>

<script>
import UiDateRangePicker from '@/components/ui/inputs/DateRangePicker';
import reports from '@/mixins/reports.mixin';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple';

import { filters } from '@/data';

export default {
    name: 'RefundsFilters',
    components: { 
        UiDateRangePicker,
        UiSelectMultiple,
    },
    mixins: [reports],
    data() {
        const range = { ...this.getInitialRange() };

        return {
            formData: {
                range: { ...range },
                status: null,
                destination: null
            },
        };
    },
    computed: {
        availableFilters() {
            return filters.refunds;
        },
    },
};
</script>
